var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "orderEvaluateForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              submitText: "回复",
              footerShow: _vm.footerShow,
              form: _vm.form,
              submitBefore: _vm.submitBefore,
            },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: `订单流转 -- ${_vm.serialNumber}` } },
                [
                  _c("col2-progress", {
                    attrs: { status: _vm.progressList, percent: _vm.progress },
                  }),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "订单信息" } },
                [
                  _c("col2-item", {
                    attrs: { label: "订购服务", text: _vm.info.subName },
                  }),
                  _c("col2-item", {
                    attrs: { label: "用户姓名", text: _vm.info.userName },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "下单类型",
                      text: _vm.overbookTypeMap[_vm.info.sourceType],
                    },
                  }),
                  _c("col2-item", {
                    attrs: { label: "联系电话", text: _vm.info.userPhone },
                  }),
                  _c("col2-item", {
                    attrs: { label: "配送地址", text: _vm.info.userAddress },
                  }),
                  _c("col2-item", {
                    attrs: { label: "预约时间", text: _vm.info.serverTime },
                  }),
                  _c("col2-item", {
                    attrs: { label: "下单时间", text: _vm.info.referTime },
                  }),
                  _c("col2-item", {
                    attrs: { label: "服务商家", text: _vm.info.shopName },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "订单状态",
                      text: _vm.orderStatusMap[_vm.info.orderStatus],
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "订单金额",
                      text: `${_vm.info.totalMoney} 元`,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "支付方式",
                      text: _vm.paymentChannelMap[_vm.info.payType],
                    },
                  }),
                  _vm.info.payStatus === "0"
                    ? _c("col2-item", {
                        attrs: {
                          label: "应付金额",
                          text: `${_vm.info.orderMoney} 元`,
                        },
                      })
                    : _c("col2-item", {
                        attrs: {
                          label: "实付金额",
                          text: `${_vm.info.realMoney} 元`,
                        },
                      }),
                  _c("col2-item", {
                    attrs: {
                      label: "支付状态",
                      text: _vm.payStatusMap[_vm.info.payStatus],
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "物流金额",
                      text: `${_vm.info.expensesMoney} 元`,
                    },
                  }),
                  !!_vm.info.useTicketInfo
                    ? _c("col2-item", {
                        attrs: {
                          label: "抵价票券",
                          text: _vm.info.useTicketInfo,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "订单内容" } },
                [
                  _vm.orderImageUrls.length
                    ? _c(
                        "col2-item",
                        { attrs: { span: 24, label: "订单图片" } },
                        [
                          _c(
                            "div",
                            [
                              _c("v-preview", {
                                attrs: { imgs: _vm.orderImageUrls },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _c(
                        "div",
                        { staticClass: "table-wrapper" },
                        [
                          _vm.orderData.length
                            ? _c("table-panel", {
                                attrs: {
                                  headers: _vm.orderHeaders,
                                  tableData: _vm.orderData,
                                  hasOperateColumn: false,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "订单描述",
                      text: _vm.content.remark,
                    },
                  }),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "订单操作" } },
                [
                  _c("col2-item", {
                    attrs: {
                      label: "接单人员",
                      text: _vm.operation.inceptUserName,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "接单时间",
                      text: _vm.operation.inceptTime,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "接单反馈",
                      text: _vm.operation.inceptFeedback,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "开始处理",
                      text: _vm.operation.startDealUserName,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "开始时间",
                      text: _vm.operation.startDealTime,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "开始处理反馈",
                      text: _vm.operation.startDealFeedback,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "处理完成",
                      text: _vm.operation.endDealUserName,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "完成时间",
                      text: _vm.operation.endDealTime,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "处理结束反馈 ",
                      text: _vm.operation.dealFeedback,
                    },
                  }),
                ],
                1
              ),
              _vm.dealInfoImageUrls.length
                ? _c(
                    "col2-block",
                    { attrs: { title: "处理情况" } },
                    [
                      _c(
                        "col2-item",
                        { attrs: { span: 24, label: "处理后图片" } },
                        [
                          _c(
                            "div",
                            [
                              _c("v-preview", {
                                attrs: { imgs: _vm.dealInfoImageUrls },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isInvoice && _vm.evaluateList && _vm.evaluateList.length
                ? _c(
                    "col2-block",
                    { attrs: { title: "用户评价" } },
                    [
                      _vm._l(_vm.evaluateList, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "evaluate-wrapper" },
                          [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("用户评价#" + _vm._s(index + 1)),
                            ]),
                            _c("div", { staticClass: "rate-row" }, [
                              _c(
                                "div",
                                { staticClass: "col2" },
                                [
                                  _c("span", { staticClass: "rate-label" }, [
                                    _vm._v("服务质量"),
                                  ]),
                                  _c("rate", {
                                    staticClass: "rate",
                                    attrs: {
                                      value: item.quality,
                                      disabled: "",
                                      "text-color": "#ff9900",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col2" },
                                [
                                  _c("span", { staticClass: "rate-label" }, [
                                    _vm._v("服务速度"),
                                  ]),
                                  _c("rate", {
                                    staticClass: "rate",
                                    attrs: {
                                      value: item.speed,
                                      disabled: "",
                                      "text-color": "#ff9900",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            item.content
                              ? _c("div", { staticClass: "eva-cell" }, [
                                  _c("span", { staticClass: "label" }, [
                                    _vm._v("评价内容:"),
                                  ]),
                                  _c("span", [_vm._v(_vm._s(item.content))]),
                                ])
                              : _vm._e(),
                            item.appraiseTime
                              ? _c("div", { staticClass: "eva-cell" }, [
                                  _c("span", { staticClass: "label" }, [
                                    _vm._v("评价时间:"),
                                  ]),
                                  _c("span", [
                                    _vm._v(_vm._s(item.appraiseTime)),
                                  ]),
                                ])
                              : _vm._e(),
                            item.reply
                              ? _c("div", { staticClass: "eva-cell" }, [
                                  _c("span", { staticClass: "label" }, [
                                    _vm._v("回复评价:"),
                                  ]),
                                  _c("span", [_vm._v(_vm._s(item.reply))]),
                                ])
                              : _vm._e(),
                            item.replyTime
                              ? _c("div", { staticClass: "eva-cell" }, [
                                  _c("span", { staticClass: "label" }, [
                                    _vm._v("回复时间:"),
                                  ]),
                                  _c("span", [_vm._v(_vm._s(item.replyTime))]),
                                ])
                              : _vm._e(),
                          ]
                        )
                      }),
                      _c("div"),
                    ],
                    2
                  )
                : _vm._e(),
              !_vm.isInvoice && _vm.footerShow
                ? _c(
                    "col2-block",
                    { attrs: { title: "处理反馈" } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("v-textarea", {
                            attrs: { placeholder: "请输入回复内容" },
                            model: {
                              value: _vm.form.evaluate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "evaluate", $$v)
                              },
                              expression: "form.evaluate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }