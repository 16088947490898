<!--
 * @Description: 工单评价详情页面
 * @Author: 小广
 * @Date: 2019-06-12 17:09:25
 * @LastEditors: 小广
 * @LastEditTime: 2019-10-16 15:19:11
 -->
<template>
  <div class="orderEvaluateForm-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      submitText="回复"
      :footerShow="footerShow"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>
         <col2-block :title="`订单流转 -- ${serialNumber}`">
          <!-- 进度 -->
          <col2-progress :status="progressList" :percent="progress" />
         </col2-block>
         <col2-block title="订单信息">
          <col2-item label="订购服务" :text="info.subName" />
          <col2-item label="用户姓名" :text="info.userName" />
          <col2-item label="下单类型" :text="overbookTypeMap[info.sourceType]" />
          <col2-item label="联系电话" :text="info.userPhone" />
          <col2-item label="配送地址" :text="info.userAddress" />
          <col2-item label="预约时间" :text="info.serverTime" />
          <col2-item label="下单时间" :text="info.referTime" />
          <col2-item label="服务商家" :text="info.shopName" />
          <col2-item label="订单状态" :text="orderStatusMap[info.orderStatus]" />
          <col2-item label="订单金额" :text="`${info.totalMoney} 元`" />
          <col2-item label="支付方式" :text="paymentChannelMap[info.payType]" />
          <col2-item v-if="info.payStatus === '0'" label="应付金额" :text="`${info.orderMoney} 元`" />
          <col2-item v-else label="实付金额" :text="`${info.realMoney} 元`" />
          <col2-item label="支付状态" :text="payStatusMap[info.payStatus]" />
          <col2-item label="物流金额" :text="`${info.expensesMoney} 元`" />
          <col2-item v-if="!!info.useTicketInfo" label="抵价票券" :text="info.useTicketInfo" />
        </col2-block>
        <col2-block title="订单内容">
          <col2-item v-if="orderImageUrls.length" :span="24" label="订单图片">
            <div>
              <v-preview :imgs="orderImageUrls"></v-preview>
            </div>
          </col2-item>
           <div v-else class="table-wrapper">
            <table-panel
              v-if="orderData.length"
              :headers="orderHeaders"
              :tableData="orderData"
              :hasOperateColumn="false">
            </table-panel>
          </div>
          <col2-item :span="24" label="订单描述" :text="content.remark" />
        </col2-block>
        <col2-block title="订单操作">
          <col2-item label="接单人员" :text="operation.inceptUserName" />
          <col2-item label="接单时间" :text="operation.inceptTime" />
          <col2-item :span="24" label="接单反馈" :text="operation.inceptFeedback" />
          <col2-item label="开始处理" :text="operation.startDealUserName" />
          <col2-item label="开始时间" :text="operation.startDealTime" />
          <col2-item :span="24" label="开始处理反馈" :text="operation.startDealFeedback" />
          <col2-item label="处理完成" :text="operation.endDealUserName" />
          <col2-item label="完成时间" :text="operation.endDealTime" />
          <col2-item :span="24" label="处理结束反馈 " :text="operation.dealFeedback" />
        </col2-block>
        <col2-block v-if="dealInfoImageUrls.length" title="处理情况" >
          <col2-item :span="24" label="处理后图片">
            <div>
              <v-preview :imgs="dealInfoImageUrls"></v-preview>
            </div>
          </col2-item>
        </col2-block>
        <col2-block  title="用户评价" v-if="!isInvoice && evaluateList && evaluateList.length" >
          <div class="evaluate-wrapper" v-for="(item, index) in evaluateList" :key="index">
            <div class="title">用户评价#{{index+1}}</div>
            <div class="rate-row">
              <div class="col2">
                <span class="rate-label">服务质量</span>
                <rate
                  :value="item.quality"
                  disabled
                  text-color="#ff9900"
                  class="rate">
                </rate>
              </div>
              <div class="col2">
                <span class="rate-label">服务速度</span>
                <rate
                  :value="item.speed"
                  disabled
                  text-color="#ff9900"
                  class="rate">
                </rate>
              </div>
            </div>
            <div class="eva-cell" v-if="item.content"><span class="label">评价内容:</span><span>{{item.content}}</span></div>
            <div class="eva-cell" v-if="item.appraiseTime"><span class="label">评价时间:</span><span>{{item.appraiseTime}}</span></div>
            <div class="eva-cell" v-if="item.reply"><span class="label">回复评价:</span><span>{{item.reply}}</span></div>
            <div class="eva-cell" v-if="item.replyTime"><span class="label">回复时间:</span><span>{{item.replyTime}}</span></div>
          </div>
          <div></div>
        </col2-block>
        <col2-block v-if="!isInvoice && footerShow" title="处理反馈">
          <el-form-item>
            <v-textarea
              v-model="form.evaluate"
              placeholder="请输入回复内容"
            ></v-textarea>
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { queryURL, replyURL } from './api'
import { overbookTypeMap, orderStatusMap, payStatusMap } from './map'
import { paymentChannelMap } from 'common/map'
import { Col2Detail, Col2Item, Col2Progress, Col2Block, TablePanel } from 'components/bussiness'
import { vPreview } from 'components/control'
import { Rate } from 'element-ui'

export default {
  name: 'OrderEvaluateForm',
  components: {
    Col2Detail,
    Col2Block,
    Col2Item,
    TablePanel,
    Col2Progress,
    vPreview,
    Rate
  },
  data () {
    return {
      overbookTypeMap,
      orderStatusMap,
      paymentChannelMap,
      payStatusMap,
      serialNumber: '',
      orderImageUrls: [],
      dealInfoImageUrls: [],
      form: {
        evaluate: '感谢您的支持，祝您生活愉快!'
      },
      submitConfig: {
        queryUrl: queryURL,
        submitUrl: replyURL,
        submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      footerShow: false,
      progress: 0,
      info: {},
      content: {},
      operation: {},
      dealInfo: {},
      evaluateList: [],
      consignee: {},
      grantDetail: {},
      orderHeaders: [
        {
          prop: 'productName',
          label: '商品名称'
        },
        {
          prop: 'price',
          label: '单价'
        },
        {
          prop: 'unit',
          label: '单位'
        },
        {
          prop: 'count',
          label: '购买数量'
        },
        {
          prop: 'shopName',
          label: '商家'
        }
      ],
      orderData: [],
      isInvoice: false
    }
  },

  computed: {
    progressList () {
      return [
        {
          name: '下单时间',
          time: this.info.referTime
        },
        {
          name: '接单时间',
          time: this.operation.inceptTime
        },
        {
          name: '开始处理',
          time: this.operation.startDealTime
        },
        {
          name: '处理完成 ',
          time: this.operation.endDealTime
        },
        {
          name: this.info.orderStatus === '5' ? '已关闭' : '已完成'
        }
      ]
    },

    canReply () {
      // 取出来的是数字 需要转换下
      let canReply = this.$store.state.permission.replyApprise
      return canReply === 1
    }
  },

  // POST

  created () {
    const { serialNumber, isInvoice } = this.$route.query
    this.isInvoice = isInvoice !== undefined
    this.serialNumber = serialNumber
    let title = this.isInvoice ? '订单详情' : '工单详情'
    this.$setBreadcrumb(title)
  },

  mounted () {
    const { id } = this.$route.query
    if (id !== undefined) {
      this.$refs.formPanel.getData({ orderId: id, orderType: 0 })
    }
  },

  methods: {
    update (data) {
      let { info, consignee, content, grantDetail, operation, dealInfo, evaluateList } = data
      this.content = content
      this.consignee = consignee
      this.grantDetail = grantDetail
      this.operation = operation
      this.dealInfo = dealInfo

      // 订单内容
      if (info) {
        info.expensesMoney = info.expensesMoney || '0.00'
        this.dealWithProgress(info)
        this.info = info
      }

      // 订单信息
      if (content && content.pictures) {
        let tempList = []
        content.pictures.forEach(item => {
          tempList.push({ src: item.url })
        })
        this.orderImageUrls = tempList
      }
      this.orderData = content.products || []

      // 处理情况 pictures
      if (dealInfo && dealInfo.pictures) {
        let tempList = []
        dealInfo.pictures.forEach(item => {
          tempList.push({ src: item.url })
        })
        this.dealInfoImageUrls = tempList
      }

      // 处理回复按钮的显示 footerShow
      if (evaluateList && evaluateList.length) {
        let footerShow = false
        evaluateList.forEach(item => {
          // 订单状态是4 已评价
          item.quality = parseInt(item.quality)
          item.speed = parseInt(item.speed)
          if (item.isReply === '0' && (info && info.orderStatus === '4')) {
            footerShow = true
          }
        })
        // 再结合权限进行判断
        this.footerShow = footerShow && this.canReply
        if (evaluateList.length > 1) {
          evaluateList.reverse()
        }

        this.evaluateList = evaluateList
      }
    },

    // 处理进度条
    dealWithProgress (info) {
      let orderStatus = parseInt(info.orderStatus)
      switch (orderStatus) {
        case 1:
          this.progress = 20
          break
        case 2:
          this.progress = 60
          break
        case 3:
          this.progress = 80
          break
        case 4:
          this.progress = 100
          break
        case 5:
          this.progress = 100
          break
        case 6:
          this.progress = 40
          break
      }
    },

    submitBefore (data) {
      data.operationDes = this.dealInfo.feedback
      data.caseId = this.$route.query.id
      data.orderId = this.$route.query.id
      data.orderStatus = this.info.orderStatus
      data.taskMemo = this.dealInfo.feedback
      return true
    }
  }
}

</script>

<style lang="scss" scoped>
.orderEvaluateForm-wrapper {
  .table-wrapper {
    width: 100%;
    margin-left: 85px;
  }

  .evaluate-wrapper {
    padding-left: 168px;
    margin-bottom: 10px;

    .title {
      margin-bottom: 10px;
      font-weight: 700;
    }
    .rate-row {
      display: flex;
      .col2 {
        display: flex;
        flex: 1;
        .rate-label {
          width: 70px;
        }
      }
    }
    .eva-cell {
      display: flex;
      margin: 10px 0;

      .label {
        width: 70px;
      }
    }
  }
}
</style>
